import React from 'react'
import Header2 from '../components/Header2'
import Footer from '../components/Footer'
import CarrerSection from '../components/CarrerSection'


export default function Career() {
  return (
    <div>
      <Header2 name="Career"/>
      <CarrerSection/>
      <Footer/>
    </div>
  )
}
