
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';



export default function ProductionSection() {
  const [t, i18n] = useTranslation("global");
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language') || 'en');

  const handleChangeLanguage = async (lang) => {
    await i18n.changeLanguage(lang);
    localStorage.setItem('language', lang);
  };

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  return (

    <div>
      <section id="projects5" className="alt-services section-bg py-5">
        <div className="container" data-aos="fade-up">
          {/* <h5 style={{ marginBottom: '20px',fontWeight:'bold ' }}>{t("Production.pro")}</h5> */}
          <h3>{t("Production.pro2")}</h3>
          <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
            {/* Project Images (Left Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Image 1 */}
                <div className="col-12">
                  <img src="../assets/img/projectimg/pro.jpg" alt="Project 1" className="img-fluid project-image" />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Project Description */}
                <div className="col-md-12">
                  <br />

                  <p style={{ margin: '1px' }}>{t("Production.ps")}</p>
                  <br />


                </div>
              </div>
            </div>
          </div>
        </div>
      </section >


      <section id="projects5" className="alt-services section-bg">
        <div className="container" data-aos="fade-up">
          {/* <h5 style={{ marginBottom: '20px',fontWeight:'bold ' }}>{t("Production.pro")}</h5> */}
          {/* <h3> {t("Production.pro2")}</h3> */}
          <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
            {/* Project Images (Left Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Image 1 */}
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/152.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '200%', height: '200px' }} />

                </div>
                {/* Image 2 */}
              </div>
            </div>

            {/* Project Description (Right Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Project Description */}
                <div className="col-md-12">
                  <br />

                  <p style={{ margin: '1px' }}>{t("Production.pro3")}</p>
                  <br />
                  <p>{t("Production.pro1")}
                  </p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section id="projects5" className="projects">
        <div className="container" data-aos="fade-up">
          <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
            {/* Paragraphs */}
            <div className="col-md-12">
              <div className="row gy-4">
                <div className="col-md-12">
                  <br />
                  <p style={{ margin: '1px' }}>
                    {t("Production.div")}
                  </p>
                </div>
              </div>
            </div>

            {/* Project Images (Bottom Row) */}
            <div className="col-md-12">
              <div className="row gy-4">
                {/* Image 1 */}
                <div className="col-md-4">
                  <img src="../assets/img/projectimg/157.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                  <p>{t("Production.cc")}</p>
                </div>
                {/* Image 2 */}
                <div className="col-md-4">
                  <img src="../assets/img/projectimg/156.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
                {/* Image 3 */}
                <div className="col-md-4">
                  <img src="../assets/img/projectimg/158.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                  <p>{t("Production.cc1")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section id="projects5" className="alt-services section-bg">
        <div className="container" data-aos="fade-up">
          <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
            {/* Paragraphs */}
            <div className="col-md-12">
              <div className="row gy-4">
                <div className="col-md-12">
                  <br />
                  <h4 style={{ margin: '1px' }}>
                    {t("Production.build")}
                  </h4>

                </div>
              </div>
            </div>

            {/* Project Images (Bottom Row) */}
            <div className="col-md-12">
              <div className="row gy-4">
                {/* Image 1 */}
                <div className="col-md-4">
                  <img src="../assets/img/projectimg/159.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                  <p>{t("Production.p10")}</p>
                </div>
                {/* Image 2 */}
                <div className="col-md-4">
                  <img src="../assets/img/projectimg/160.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                  <p>{t("Production.p11")}</p>
                </div>
                {/* Image 3 */}
                <div className="col-md-4">
                  <img src="../assets/img/projectimg/161.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                  <p>{t("Production.p12")}</p>
                </div>
                <div className="col-md-4">
                  <img src="../assets/img/projectimg/162.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                  <p>{t("Production.p13")}</p>
                </div>
                <div className="col-md-4">
                  <img src="../assets/img/projectimg/184.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                  <p>{t("Production.p14")} </p>
                </div>
                <div className="col-md-4">
                  <img src="../assets/img/projectimg/181.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />

                </div>

              </div>
            </div>
          </div>
        </div>
      </section>

    </div >

  )
}