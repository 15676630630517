import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ContactSection = () => {
  const [contactInfo, setContactInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchContactInfo = async () => {
      try {
        const response = await axios.get('https://kolarnk.up.railway.app/contact');
        setContactInfo(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching contact info:', error);
        setLoading(false);
      }
    };

    fetchContactInfo();
  }, []);

  return (
    <section id="contact" className="contact mb-4" data-aos="fade-up" data-aos-delay="100">
      <div className="container">
        {loading ? (
          // Show loading indicator with a spinning icon
          <div className="text-center">
            <p>Loading...</p>
            <i className="fas fa-spinner fa-spin" style={{ fontSize: '2em', color: '#007bff' }}></i>
          </div>
        ) : (
          <div className="row gy-4">
            {contactInfo && (
              <>
                <div className="col-lg-6">
                  <div className="info-item d-flex flex-column justify-content-center align-items-center">
                    <i className="bi bi-map"></i>
                    <h3>Our Address</h3>
                    <p>{contactInfo.address}</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="info-item d-flex flex-column justify-content-center align-items-center">
                    <i className="bi bi-envelope"></i>
                    <h3>Email Us</h3>
                    <p><a href={`mailto:${contactInfo.email}`}>{contactInfo.email}</a></p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="info-item d-flex flex-column justify-content-center align-items-center">
                    <i className="bi bi-telephone"></i>
                    <h3>Call Us</h3>
                    <p><a href={`tel:${contactInfo.mobileNumber}`}>{contactInfo.mobileNumber}</a></p>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </section>

  );
};

export default ContactSection;
