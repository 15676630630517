import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Sidebar from './SideBar';

const Header1 = () => {
  const [t, i18n] = useTranslation("global");
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language') || 'en');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showDropdown1, setShowDropdown1] = useState(false);
  const [showDropdown2, setShowDropdown2] = useState(false);
  const [showDropdown3, setShowDropdown3] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);


  // Function to handle language change
  const handleChangeLanguage = async (lang) => {
    await i18n.changeLanguage(lang);
    // Save the selected language in local storage
    localStorage.setItem('language', lang);
  };

  // useEffect to set the language on component mount
  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  const handleIconClick = () => {
    // Toggle the state to open/close the sidebar
    setIsSidebarOpen(!isSidebarOpen);
  };



  const getDropdownIcon = (dropdownNumber) => {
    // Return the appropriate icon based on the dropdown state
    switch (dropdownNumber) {
      case 1:
        return showDropdown1 ? "bi bi-chevron-up" : "bi bi-chevron-down";
      case 2:
        return showDropdown2 ? "bi bi-chevron-up" : "bi bi-chevron-down";
      case 3:
        return showDropdown3 ? "bi bi-chevron-up" : "bi bi-chevron-down";
      default:
        return "bi bi-chevron-down";
    }
  };

  const handleDropdownClick = (dropdownNumber, event) => {
    // Toggle the state of the clicked dropdown
    switch (dropdownNumber) {
      case 1:
        setShowDropdown1(!showDropdown1);
        break;
      case 2:
        setShowDropdown2(!showDropdown2);
        break;
      case 3:
        setShowDropdown3(!showDropdown3);
        break;
      default:
        break;
    }

    // Stop propagation only if the click was on the dropdown indicator
    const targetClasses = event.target.classList;
    if (targetClasses.contains('dropdown-indicator')) {
      event.stopPropagation();
    }
  };

  const headerStyle = {
    marginTop: 10,
  };

  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };

  const logoContainerStyle = {
    display: 'flex',
    alignItems: 'center',
  };

  const chairmanStyle = {
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  };

  const chairmanImageStyle = {
    alignSelf: 'center',
  };

  const chairmanTextStyle = {
    color: 'white',
    fontSize: 7,
    textAlign: 'center',
    marginTop: -10,
    fontWeight: 'bold',
  };


  const headerLinkActiveStyle = {
    color: '#fff',
  };




  const dropdownLinkStyle = {
    padding: '10px 20px',
    fontSize: 15,
    textTransform: 'none',
    color: 'var(--color-default)',
    fontWeight: 400,
  };

  const sidebarContainerStyle = {
    right: -100,
    width: '100%',
    maxWidth: 400,
    bottom: 0,
    transition: '0.3s',
    zIndex: 9997,
  };

  const sidebarUlStyle = {
    position: 'absolute',
    inset: 0,
    padding: '50px 0 10px 0',
    margin: 0,
    background: 'rgba(0, 0, 0, 0.8)',
    overflowY: 'auto',
    transition: '0.3s',
    zIndex: 9998,
  };

  const sidebarLinkStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 20px',
    fontFamily: 'var(--font-primary)',
    fontSize: 15,
    fontWeight: 500,
    color: 'rgba(255, 255, 255, 0.7)',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    transition: '0.3s',
  };

  const sidebarLinkActiveStyle = {
    color: '#fff',
  };

  const sidebarIconStyle = {
    fontSize: 12,
    lineHeight: 0,
    marginLeft: 5,
  };




  const headerLinkStyle = {
    display: 'block',
    padding: '15px 20px',
    color: 'rgba(255, 255, 255, 0.6)',
    textDecoration: 'none',
    textTransform: 'uppercase',
    position: 'relative',
    fontSize: '14px',
    fontWeight: 500,
    transition: '0.3s',
  };

  const dropdownIndicatorStyle = {
    fontSize: '12px',
    position: 'absolute',
    top: '50%',
    right: '15px',
    transform: 'translateY(-50%)',
  };

  const dropdownContainerStyle = {
    display: 'none',
    position: 'absolute',
    top: '100%',
    left: 0,
    listStyle: 'none',
    padding: 0,
    margin: '10px 0 0 0',
    background: '#fff',
    boxShadow: '0px 0px 30px rgba(127, 137, 161, 0.25)',
    transition: '0.3s',
    zIndex: 99,
  };

  const horizontalNavStyle = {
    display: 'flex',
    listStyle: 'none',
    margin: 0,
    padding: 0,
  };

  const horizontalNavItemStyle = {
    marginRight: '20px',
  };

  const languageLinkStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 20px',
    fontSize: 15,
    fontWeight: 500,
    color: 'rgba(255, 255, 255, 0.7)',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    position: 'relative', // Add position:relative
    zIndex: 1, // Ensure the language dropdown is above other elements
    transition: '0.3s',
  };

  const languageIconStyle = {
    fontSize: 12,
    lineHeight: 0,
    marginLeft: 5,
    position: 'absolute', // Add position:absolute
    top: '50%', // Center vertically
    right: '15px', // Adjust as needed
    transform: 'translateY(-50%)', // Center vertically
    zIndex: 2, // Ensure the language icon is above other elements
  };

  return (
    <header id="header" className="header d-flex align-items-center " style={headerStyle}>
      <div className="container-fluid container-xl d-flex align-items-center justify-content-between" style={containerStyle}>

        <div className="logo d-flex member-img" style={logoContainerStyle}>

          {/* <div className='d-none d-md-flex' style={chairmanStyle}>
            <img className="img-fluid" style={chairmanImageStyle} src="https://planning.karnataka.gov.in/frontend/opt1/images/cm_logo/chief_minister_of_karrnataka_icon.png?1704525943" alt="Chairman" />
            <div className="">
              <h4 style={chairmanTextStyle}>Shri Siddaramaiah</h4>
              <p style={{ ...chairmanTextStyle, marginTop: -10, fontSize: 7 }}> Chairman</p>
            </div>
          </div> */}

          <div className='d-flex align-items-center'>
            <a href="/">
              <h1 className="fs-6 fs-md-2 fs-lg-1" data-aos="fade-down">
                {t("header.title.message")}<span>.</span>
              </h1>
            </a>
          </div>
        </div>
        <nav id="" className={`navbar d-sm-none d-md-flex ${isSidebarOpen ? 'menu-open' : ''}`} style={{ ...horizontalNavStyle }} >
          <ul style={horizontalNavStyle}>
            <li style={horizontalNavItemStyle}><a href="/" className="active" style={headerLinkStyle}>{t("header.menus.menu1.message")}</a></li>
            <li style={horizontalNavItemStyle}><a href="about" style={headerLinkStyle}>{t("header.menus.menu2.message")}</a></li>


            <li className="dropdown">
              <a href="services">
                <span>{t("header.menus.menu3.message")}</span> <i className="bi bi-chevron-down dropdown-indicator"></i>
              </a>
              <ul>
                <li><a href="training">{t('header.menus.menu3.submenus.submenu1.message')}</a></li>
                <li><a href="production">{t('header.menus.menu3.submenus.submenu2.message')}</a></li>
                <li><a href="construction">{t('header.menus.menu3.submenus.submenu3.message')}</a></li>
              </ul>
            </li>

            <li style={horizontalNavItemStyle}><a href="projects" style={headerLinkStyle}>{t('header.menus.menu4.message')}</a></li>
            <li className="dropdown">
              <a href="administration">
                <span>{t('header.menus.menu5.message')}</span> <i className="bi bi-chevron-down dropdown-indicator"></i>
              </a>
              <ul>
                <li><a href="administration">{t('header.menus.menu5.submenus.submenu1.message')}</a></li>
                <li><a href="rti">{t('header.menus.menu5.submenus.submenu4.message')}</a></li>
                <li><a href="meetings">{t('header.menus.menu5.submenus.submenu5.message')}</a></li>
                <li><a href="career">{t('header.menus.menu5.submenus.submenu2.message')}</a></li>
                <li><a href="newsandtenders">{t('header.menus.menu5.submenus.submenu3.message')}</a></li>
              </ul>
            </li>
            <li style={horizontalNavItemStyle}><a href="contact" className="link" style={headerLinkStyle}>{t('header.menus.menu6.message')}</a></li>
            <li className="dropdown" style={horizontalNavItemStyle}>
              <a style={horizontalNavItemStyle}>
                <span style={horizontalNavItemStyle}>{t("header.Lang.message")}</span> <i className="bi bi-chevron-down dropdown-indicator link"></i>
              </a>
              <ul>
                <li><a type="button" onClick={() => handleChangeLanguage("kn")}>ಕನ್ನಡ</a></li>
                <li><a type="button" onClick={() => handleChangeLanguage("en")}>English</a></li>
              </ul>
            </li>
          </ul>
        </nav>

        <div className="logo  member-img" style={{ marginLeft: 20 }}>
          {/* <div className='d-none d-lg-flex ' style={chairmanStyle}>
            <img className="img-fluid" style={chairmanImageStyle} src="https://planning.karnataka.gov.in/frontend/opt1/images/cm_logo/chief_minister_of_karrnataka_icon.png?1704525943" alt="Chairman" />
            <div className="">
              <h4 style={chairmanTextStyle}>Shri Siddaramaiah</h4>
              <p style={{ ...chairmanTextStyle, marginTop: -10, fontSize: 7 }}> Executive Chairman</p>
            </div>
          </div> */}
        </div>
        <div className={isSidebarOpen ? 'hide' : 'open'} onClick={handleIconClick}>
          <i className={isSidebarOpen ? 'hide' : 'open mobile-nav-show bi bi-list'} ></i>
        </div>


        <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>


          <div className="sidebar-container" style={sidebarContainerStyle}>

            <div class="d-flex flex-row align-items-center">
              <h1 class="sidebar-heading">Menu</h1>
              <div class="icon-container sidebar-heading" onClick={handleIconClick}>
                <i class="fas fa-times"></i>
              </div>
            </div>


            <div className="sidebar-content">
              <ul>
                <li><a href="/" className={`active link ${isSidebarOpen ? 'sidebar-active' : ''}`} style={sidebarLinkStyle}>{t("header.menus.menu1.message")}</a></li>
                <li><a href="about" className={`link ${isSidebarOpen ? 'sidebar-active' : ''}`} style={sidebarLinkStyle}>{t("header.menus.menu2.message")}</a></li>
                <li className="dropdown" onClick={(event) => handleDropdownClick(1, event)}>
                  <a className={`link ${isSidebarOpen ? 'sidebar-active' : ''}`} style={sidebarLinkStyle}>
                    <span>{t("header.menus.menu3.message")}</span> <i className={getDropdownIcon(1) + " dropdown-indicator"} style={sidebarIconStyle}></i>
                  </a>
                  {showDropdown1 && (
                    <ul>
                      <li><a href="training" className={`link ${isSidebarOpen ? 'sidebar-active' : ''}`} style={sidebarLinkStyle}>{t('header.menus.menu3.submenus.submenu1.message')}</a></li>
                      <li><a href="production" className={`link ${isSidebarOpen ? 'sidebar-active' : ''}`} style={sidebarLinkStyle}>{t('header.menus.menu3.submenus.submenu2.message')}</a></li>
                      <li><a href="construction" className={`link ${isSidebarOpen ? 'sidebar-active' : ''}`} style={sidebarLinkStyle}>{t('header.menus.menu3.submenus.submenu3.message')}</a></li>

                    </ul>
                  )}
                </li>
                <li><a href="projects" className={`link ${isSidebarOpen ? 'sidebar-active' : ''}`} style={sidebarLinkStyle}>{t('header.menus.menu4.message')}</a></li>
                <li className="dropdown" onClick={(event) => handleDropdownClick(2, event)}>
                  <a className={`link ${isSidebarOpen ? 'sidebar-active' : ''}`} style={sidebarLinkStyle}>
                    <span>{t('header.menus.menu5.message')}</span> <i className={getDropdownIcon(2) + " dropdown-indicator"} style={sidebarIconStyle}></i>
                  </a>
                  {showDropdown2 && (
                    <ul>
                      <li><a href="administration" className={`link ${isSidebarOpen ? 'sidebar-active' : ''}`} style={sidebarLinkStyle}>{t('header.menus.menu5.submenus.submenu1.message')}</a></li>
                      <li><a href="rti" className={`link ${isSidebarOpen ? 'sidebar-active' : ''}`} style={sidebarLinkStyle}>{t('header.menus.menu5.submenus.submenu4.message')}</a></li>
                      <li><a href="meetings" className={`link ${isSidebarOpen ? 'sidebar-active' : ''}`} style={sidebarLinkStyle}>{t('header.menus.menu5.submenus.submenu5.message')}</a></li>
                      <li><a href="career" className={`link ${isSidebarOpen ? 'sidebar-active' : ''}`} style={sidebarLinkStyle}>{t('header.menus.menu5.submenus.submenu2.message')}</a></li>
                      <li><a href="newsandtenders" className={`link ${isSidebarOpen ? 'sidebar-active' : ''}`} style={sidebarLinkStyle}>{t('header.menus.menu5.submenus.submenu3.message')}</a></li>
                    </ul>
                  )}
                </li>
                <li><a href="contact" className={`link ${isSidebarOpen ? 'sidebar-active' : ''}`} style={sidebarLinkStyle}>{t('header.menus.menu6.message')}</a></li>

                <a className={`link ${isSidebarOpen ? 'sidebar-active' : ''}`} style={languageLinkStyle}>
                  <li className="dropdown" onClick={(event) => handleDropdownClick(3, event)}>
                    <span>{t("header.Lang.message")}</span> <i className={getDropdownIcon(3) + " dropdown-indicator"} style={languageIconStyle}></i>
                  </li>
                </a>
                {showDropdown3 && (
                  <ul>
                    <li><a type="button" onClick={() => handleChangeLanguage("kn")} className={`link ${isSidebarOpen ? 'sidebar-active' : ''}`} style={sidebarLinkStyle}>ಕನ್ನಡ</a></li>
                    <li><a type="button" onClick={() => handleChangeLanguage("en")} className={`link ${isSidebarOpen ? 'sidebar-active' : ''}`} style={sidebarLinkStyle}>English</a></li>
                  </ul>
                )}

              </ul>
            </div>
          </div>
        </div>
        {/* <div className='d-flex d-lg-none ml-4 dot-menu'  >
            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512" style={{ fill: 'white' }}>
              <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
            </svg>
         
           

          </div> */}


      </div>
    </header>
  );
};

export default Header1;
