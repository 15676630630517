import React,{ useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';



import { Link } from 'react-router-dom'
export default function NewsSection() {

  const [t, i18n] = useTranslation("global");
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language') || 'en');

  const handleChangeLanguage = async (lang) => {
    await i18n.changeLanguage(lang);
    localStorage.setItem('language', lang);
  };

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);
  return (
    
    <section id="career" className="career section-bg">
    <div className="container" data-aos="fade-up">
      <div className="row justify-content-around gy-4">
      <div className="col-lg-5 d-flex flex-column justify-content-center">
  <h3>{t("News.new")}</h3>
  <p>{t("News.new1")}</p>
  <Link to="/contact"className="contact-link">{t("News.cont")}</Link> {/* Navigate to Contact page */}
</div>
        <div className="col-lg-6">
          {/* You can add an image or any other content related to the career section */}
        </div>
      </div>
    </div>
  </section>
);
}




   