import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';

const ConstrctionS = () => {
  const [t, i18n] = useTranslation("global");
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language') || 'en');

  const handleChangeLanguage = async (lang) => {
    await i18n.changeLanguage(lang);
    localStorage.setItem('language', lang);
  };

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  return (
    <div>
      <section id="production" className="alt-services section-bg">
      <div className="container" data-aos="fade-up">
          <h4 style={{ fontWeight: 'bold' }}>{t("Construction.building_activities")} </h4>
          <div className="row" style={{ marginTop: '25px' }}>
            <div className="col-lg-4">
              <img src="../assets/img/projectimg/140.jpg" alt="Production Image 1" className="card-img-top" />
              <p>{t("Construction.activity_1")}</p>
            </div>
            <div className="col-lg-4">
              <img src="../assets/img/projectimg/139.jpg" alt="Production Image 2" className="card-img-top" />
             <p>{t("Construction.activity_2")}</p>
            </div>
            <div className="col-lg-4">
              <img src="../assets/img/projectimg/138.jpg" alt="Production Image 3" className="card-img-top" />
              <p>{t("Construction.activity_3")}</p>
            </div>
          </div>
        </div>
      </section>

      <section id="production" className="production-section">
      <div className="container" data-aos="fade-up">
          <div className="row" style={{ marginTop: '25px' }}>
            <div className="col-lg-4">
              <img src="../assets/img/projectimg/141.jpg" alt="Production Image 1" className="card-img-top" />
              <p>{t("Construction.activity_4")}</p>
            </div>
            <div className="col-lg-4">
              <img src="../assets/img/projectimg/142.jpg" alt="Production Image 2" className="card-img-top" />
              <p>{t("Construction.activity_5")}</p>
            </div>
          </div>
        </div>
      </section>

      <section id="production" className="alt-services section-bg">
      <div className="container" data-aos="fade-up">
    <h4 className="universal-paragraph"> {t("Construction.anganavadi_building")} </h4>
    <div className="row" style={{ marginTop: '25px' }}>
      <div className="col-lg-4" style={{ marginBottom: '25px' }}>
        <img src="../assets/img/projectimg/143.jpg" alt="Production Image 1" className="card-img-top" />
      </div>
      <div className="col-lg-4" style={{ marginBottom: '25px' }}>
        <img src="../assets/img/projectimg/144.jpg" alt="Production Image 2" className="card-img-top" />
      </div>
      <div className="col-lg-4" style={{ marginBottom: '25px' }}>
        <img src="../assets/img/projectimg/145.jpg" alt="Production Image 3" className="card-img-top" />
      </div>
    </div>
    <div className="row">
      <div className="col-lg-4" style={{ marginBottom: '25px' }}>
        <img src="../assets/img/projectimg/146.jpg" alt="Production Image 4" className="card-img-top" />
      </div>
      <div className="col-lg-4" style={{ marginBottom: '25px' }}>
        <img src="../assets/img/projectimg/147.jpg" alt="Production Image 5" className="card-img-top" />
      </div>
    </div>
  </div>
</section>


      <section id="production" className="production-section">
      <div className="container" data-aos="fade-up">
          <h4 className="universal-paragraph">{t("Construction.nivasa_madel_anganavadi_building")}</h4>
          <div className="row" style={{ marginTop: '25px' }}>
            <div className="col-lg-4">
              <img src="../assets/img/projectimg/148.jpg" alt="Production Image 1" className="card-img-top" />
            </div>
            <div className="col-lg-4">
              <img src="../assets/img/projectimg/149.jpg" alt="Production Image 2" className="card-img-top" />
            </div>
          </div>
        </div>
      </section>

      <section id="production" className="alt-services section-bg">
      <div className="container" data-aos="fade-up">
          <div className="row" style={{ marginTop: '25px' }}>
            <div className="col-lg-4">
              <img src="../assets/img/projectimg/150.jpg" alt="Production Image 1" className="card-img-top" />
              <p>{t("Construction.additional_class_rooms")}</p>
              </div>
            <div className="col-lg-4">
              <img src="../assets/img/projectimg/151.jpg" alt="Production Image 2" className="card-img-top" />
              <p>{t("Construction.nada_kacheri_building")}</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
export default ConstrctionS;