import React from 'react'
import Header2 from '../components/Header2'
import Footer from '../components/Footer'
// import ProjectsSection from '../components/ProjectSection'
import ActuallProjects from '../components/ActuallProjects'
import OngoingProjects from '../components/OngoingProjects'

export default function Projects() {
  return (
    <div>
        <Header2 name="Projects"/>
        <OngoingProjects/>
        <ActuallProjects/>
        {/* <ProjectsSection/> */}
        <Footer/>
    </div>
  )
}
