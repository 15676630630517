import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const DepartmentsSection = () => {
  const [t, i18n] = useTranslation("global");
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://kolarnk.up.railway.app/projects/Department');
        const responseData = response.data;
        if (responseData && Array.isArray(responseData.projectTrainings)) {
          setDepartments(responseData.projectTrainings);
        } else {
          console.error('Departments array not found in response:', responseData);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false); // Set loading to false after the data is fetched
      }
    };

    fetchData();
  }, []);

  // Log departments to check its value
  console.log('Departments:', departments);

  return (
    <section id="departments" className="departments">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h2>{t("department.title")}</h2>
        </div>
        {loading ? (
          // Show loading indicator with a spinning icon
          <div className="text-center">
            <p>Loading...</p>
            <i className="fas fa-spinner fa-spin" style={{ fontSize: '2em', color: '#007bff' }}></i>
          </div>
        ) : (
          <div className="row">
            {departments.map((department, index) => (
              <div key={index} className="col-md-6 col-lg-4 mb-4" data-aos="fade-up" data-aos-delay={index * 100}>
                <div className="card h-100 border-0 shadow">
                  <div className="card-body">
                    <h3 className="card-title">{department.name}</h3>
                    <p className="card-text">{department.description}</p>
                    {/* You can add more content or styles for each department here */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default DepartmentsSection;
