import React from 'react';

const Header = () => {
  return (
    <header id="header" className="header d-flex align-items-center">
      <div className="container-fluid container-xl d-flex align-items-center justify-content-between">

      
     
        <i className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"></i>
       
      </div>
    </header>
  );
};

export default Header;
