
import React,{ useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function TraningSection() {
  const [t, i18n] = useTranslation("global");
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language') || 'en');

  const handleChangeLanguage = async (lang) => {
    await i18n.changeLanguage(lang);
    localStorage.setItem('language', lang);
  };

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  return (
    <div>
    <section id="projects" className="alt-services section-bg">
  <div className="container" data-aos="fade-up">
      
        <div className="row">
          <div className="col-lg-12">
            <h4 className="section-heading" style={{fontWeight:'bold',fontSize:"28px"}}>{t("Training.act")}</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <h5 className="universal-paragraph" >
            {t("Training.h5")}
            </h5>
          </div>
        </div>
        <div className="row" style={{marginTop:'25px'}}>
          <div className="col-lg-3 col-md-6">
            <div className="image-container">
              <img src="../assets/img/projectimg/125.jpg" alt="Image 1" className="img-fluid" />
              <div className="overlay"></div>
              <div className="text">
                
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="image-container">
              <img src="../assets/img/projectimg/126.jpg" alt="Image 2" className="img-fluid" />
              <div className="overlay"></div>
              <div className="text">
                
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-mda-6">
            <div className="image-container" >
              <img src="../assets/img/projectimg/127.jpg" alt="Image 3" className="img-fluid" />
              <div className="overlay"></div>
              <div className="text">
                
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="image-container">
              <img src="../assets/img/projectimg/128.jpg" alt="Image 4" className="img-fluid" />
              <div className="overlay"></div>
              <div className="text">
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section id="projects5" className="projects">
    <div className="container" data-aos="fade-up">
        <div className="row">
          <div className="col-lg-12">
           
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <h5 >
            {t("Training.h6")}.
            </h5>
          </div>
        </div>
        <div className="row" style={{marginTop:'25px'}}>
          <div className="col-lg-3 col-md-6">
            <div className="image-container">
              <img src="../assets/img/projectimg/129.jpg" alt="Image 1" className="img-fluid" />
              <div className="overlay"></div>
              <div className="text">
                
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="image-container">
              <img src="../assets/img/projectimg/130.jpg" alt="Image 2" className="img-fluid" />
              <div className="overlay"></div>
              <div className="text">
                
              </div>
            </div>
          </div>
        </div>
      </div>
</section>


<section id="projects5" className="alt-services section-bg">
  <div className="container" data-aos="fade-up">
    <div className="row">
      <div className="col-lg-12"></div>
    </div>
    <div className="row">
      <div className="col-lg-12"></div>
    </div>
    <div className="row">
      <div className="col-lg-3 col-md-6">
        <div className="image-container">
          <img src="../assets/img/projectimg/134.jpg" alt="Image 1" className="img-fluid" />
          <div className="overlay"></div>
        </div>
        <p>{t("Training.h7")}</p>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="image-container">
          <img src="../assets/img/projectimg/133.jpg" alt="Image 2" className="img-fluid" />
          <div className="overlay"></div>
        </div>
        <p>{t("Training.h8")}</p>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="image-container">
          <img src="../assets/img/projectimg/132.jpg" alt="Image 3" className="img-fluid" />
          <div className="overlay"></div>
        </div>
        <p>{t("Training.h9")}</p>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="image-container">
          <img src="../assets/img/projectimg/131.jpg" alt="Image 4" className="img-fluid" />
          <div className="overlay"></div>
        </div>
        <p>{t("Training.h10")}</p>
      </div>
    </div>
  </div>
</section>


<section id="projects5" className="projects">
    <div className="container" data-aos="fade-up">
        <div className="row">
          <div className="col-lg-12">
           
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            
          <h5>{t("Training.h11")} </h5>
          </div>
        </div>
        <div className="row" style={{marginTop:'25px'}}>
      
      <div className="col-lg-3 col-md-6">
        <div className="image-container">
          <img src="../assets/img/projectimg/136.jpg" alt="Image 2" className="img-fluid" />
          <div className="overlay"></div>
        </div>
        <p>{t("Training.h12")}</p>
      </div>
      
      <div className="col-lg-3 col-md-6">
        <div className="image-container">
          <img src="../assets/img/projectimg/137.jpg" alt="Image 3" className="img-fluid" />
          <div className="overlay"></div>
        </div>
        <p>{t("Training.h13")}</p>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="image-container">
          <img src="../assets/img/projectimg/135.jpg" alt="Image 1" className="img-fluid" />
          <div className="overlay"></div>
        </div>
        <p>{t("Training.h14")}</p>
      </div>
    </div>
      </div>
</section>




    </div>
  );
}