import React,{ useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Header2 from '../components/Header2';
import Footer from '../components/Footer';
import ManagementMap from '../components/Management';

export default function Administration() {
  const [t, i18n] = useTranslation("global");
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language') || 'en');

  const handleChangeLanguage = async (lang) => {
    await i18n.changeLanguage(lang);
    localStorage.setItem('language', lang);
  };

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);
  return (
    <>
      <Header2 name="Administration" />
      
<section id="alt-services-2" className="alt-services section-bg">
  <div className="container" data-aos="fade-up">
    <div className="row justify-content-around gy-4">
      <div className="col-lg-5 d-flex flex-column justify-content-center">
        <h3>{t("about.subhead")}
</h3>
        <p> 
        {t("about.subpara")}
</p>
      </div>
      <div className="col-lg-6" style={{ marginTop: '1.4em', marginBottom: '0.8em', }}>
  <img src="../assets/img/projectimg/admin.jpg" alt="Your Image" style={{ width: '105%', height: '100%', objectFit: 'cover' }} />
</div>

    </div>
  </div>
</section>

<Footer/>
    </>
  );
};