import React from 'react'
import Footer from '../components/Footer'
import Header2 from '../components/Header2'
import ProjectDescription from '../components/ProjecrdesSection'

export default function Projectdescription() {
  return (
        <>
        <Header2 name="Project Description"/>
<ProjectDescription/>
      <Footer/>
     
      </>
  )
}
