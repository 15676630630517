import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
export default function Governbody() {

  const [t, i18n] = useTranslation("global");
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language') || 'en');

  const handleChangeLanguage = async (lang) => {
    await i18n.changeLanguage(lang);
    localStorage.setItem('language', lang);
  };

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);
  return (
    <div>
      <section id="projects" className="projects">
        <div className="container" data-aos="fade-up">
          <h3 style={{ color: 'black', fontWeight: 'bold' }}>{t("about.subhead2")}</h3>
          <h5 style={{ marginBottom: '20px' }}>{t("about.subpara2")}</h5>
          <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
            {/* Project Description (Left Side) */}
            <div className="col-md-6">
              <table className="table table-responsive">
                <thead>
                  <tr>
                    <th scope="col">{t("about.subpara3")}</th>
                    <th scope="col">{t("about.subpara4")}</th>
                    <th scope="col">{t("about.subpara5")}</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.from({ length: 16 }, (_, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{t(`about.t${i * 2 + 1}`)}</td>
                      <td>{t(`about.t${i * 2 + 2}`)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* Project Images (Right Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                <div className="col-lg-12">
                  <img
                    src="../assets/img/projectimg/123.jpg"
                    alt="Your Image"
                    className="img-fluid"
                    style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="projects" className="alt-services section-bg" style={{ marginBottom: '100px' }}>
        <div className="container" data-aos="fade-up" style={{ marginTop: '-50px' }}>
          <h3 style={{ color: 'black', fontWeight: 'bold' }}>{t("about.subpara6")}</h3>
          <h5 style={{ marginBottom: '20px' }}>{t("about.subpara7")}</h5>
          <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
            {/* Project Description (Left Side) */}
            <div className="col-md-6">
              <table className="table table-responsive">
                <thead>
                  <tr>
                    <th scope="col">{t("about.subpara3")}</th>
                    <th scope="col">{t("about.subpara4")}</th>
                    <th scope="col">{t("about.subpara5")}</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.from({ length: 5 }, (_, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{t(`about.t${i * 2 + 33}`)}</td>
                      <td>{t(`about.t${i * 2 + 34}`)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* Project Images (Right Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                <div className="col-lg-12">
                  <img
                    src="../assets/img/projectimg/124.jpg"
                    alt="Your Image"
                    className="img-fluid"
                    style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
}
