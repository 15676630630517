import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const AboutSection = () => {
  const [t, i18n] = useTranslation("global")
  // State to track the selected language
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language') || 'en');

  // Function to handle language change
  const handleChangeLanguage = async (lang) => {
    await i18n.changeLanguage(lang);
    // Save the selected language in local storage
    localStorage.setItem('language', lang);
  };

  // useEffect to set the language on component mount
  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);
  const handleIconClick = () => {
    // Handle the click event here, e.g., navigate to a specific page or perform an action
    console.log('Icon clicked!');
  };

  // const sectionStyle = {
  //   backgroundImage: 'url(https://images.deccanherald.com/deccanherald/import/sites/dh/files/articleimages/2023/05/01/the-karnataka-assembly-credit-dh-file-photo-1214461-1682937165.jpg?w=1200&h=675&auto=format%2Ccompress&fit=max&enlarge=true)',
  // };

  // const imgBgStyle = {
  //   backgroundImage: 'url(assets/img/alt-services-2.jpg)',
  // };

  return (
    <>
      {/* <section id="about" className="about mt-4 mb-4">
  <div className="container" data-aos="fade-up">
    <div className="row position-relative">
      <div className="col-lg-12">
        <div className="about-content">
          <h2 style={{ textAlign: 'left', marginBottom: '20px' }}>{t("about.header")}</h2>
          <div className="our-story">
            <h4>{t("about.year")}</h4>
            <h3 style={{ textAlign: 'left', marginBottom: '20px', color:'gray' }}>{t("about.storyhead")}</h3>
            <p className="about-paragraph" style={{ textAlign: 'justify', width: '150%', margin: '0 auto' }}>
              {t("about.description1")}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="row mt-4">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-md-3">
            <div className="image-container">
              <img src="../assets/img/projectimg/119.jpg" alt="Image 1" className="img-fluid" style={{ width: '100%', height: '150px' }} />
              <p style={{ textAlign: 'center' }}>{t("about.description3")}</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="image-container">
              <img src="../assets/img/projectimg/120.jpg" alt="Image 2" className="img-fluid" style={{ width: '100%', height: '150px' }} />
              <p style={{ textAlign: 'center' }}>{t("about.description2")}</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="image-container">
              <img src="../assets/img/projectimg/121.jpg" alt="Image 3" className="img-fluid" style={{ width: '100%', height: '150px' }} />
              <p style={{ textAlign: 'center' }}>{t("about.description4")}</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="image-container">
              <img src="../assets/img/projectimg/122.jpg" alt="Image 4" className="img-fluid" style={{ width: '100%', height: '150px' }} />
              <p style={{ textAlign: 'center' }}>{t("about.description5")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>








<section id="alt-services-2" className="alt-services section-bg">
  <div className="container" data-aos="fade-up">
    <div className="row justify-content-around gy-4">
      <div className="col-lg-5 d-flex flex-column justify-content-center">
        <h3>{t("about.subhead")}
</h3>
        <p> 
        {t("about.subpara")}
</p>
      </div>
      <div className="col-lg-6" style={{ marginTop: '1.4em', marginBottom: '0.8em', }}>
  <img src="../assets/img/projectimg/admin.jpg" alt="Your Image" style={{ width: '105%', height: '100%', objectFit: 'cover' }} />
</div>

    </div>
  </div>
</section> */}<section id="about" className="about mt-4 mb-4">
        <div className="container" data-aos="fade-up">
          <div className="row position-relative">
            <div className="col-lg-12">
              <div className="about-content">
                <h2 style={{ textAlign: 'left', marginBottom: '20px' }}>{t("about.header")}</h2>
                <div className="our-story">
                  <h4>{t("about.year")}</h4>
                  <h3 style={{ textAlign: 'left', marginBottom: '20px', color: 'gray' }}>{t("about.storyhead")}</h3>
                  <p className="about-paragraph" style={{ textAlign: 'justify', margin: '0 auto' }}>
                    {t("about.description1")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-md-3 col-sm-6 mb-4">
                  <div className="image-container">
                    <img src="../assets/img/projectimg/119.jpg" alt="Image 1" className="img-fluid" style={{ width: '100%', height: '150px', objectFit: 'cover' }} />
                    <p style={{ textAlign: 'center' }}>{t("about.description3")}</p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 mb-4">
                  <div className="image-container">
                    <img src="../assets/img/projectimg/120.jpg" alt="Image 2" className="img-fluid" style={{ width: '100%', height: '150px', objectFit: 'cover' }} />
                    <p style={{ textAlign: 'center' }}>{t("about.description2")}</p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 mb-4">
                  <div className="image-container">
                    <img src="../assets/img/projectimg/121.jpg" alt="Image 3" className="img-fluid" style={{ width: '100%', height: '150px', objectFit: 'cover' }} />
                    <p style={{ textAlign: 'center' }}>{t("about.description4")}</p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 mb-4">
                  <div className="image-container">
                    <img src="../assets/img/projectimg/122.jpg" alt="Image 4" className="img-fluid" style={{ width: '100%', height: '150px', objectFit: 'cover' }} />
                    <p style={{ textAlign: 'center' }}>{t("about.description5")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="alt-services-2" className="alt-services section-bg">
        <div className="container" data-aos="fade-up">
          <div className="row justify-content-around gy-4">
            <div className="col-lg-5 d-flex flex-column justify-content-center">
              <h3>{t("about.subhead")}</h3>
              <p>{t("about.subpara")}</p>
            </div>
            <div className="col-lg-6" style={{ marginTop: '1.4em', marginBottom: '0.8em' }}>
              <img src="../assets/img/projectimg/admin.jpg" alt="Your Image" className="img-fluid" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </div>
          </div>
        </div>
      </section>


    </>
  );
};

export default AboutSection;
