import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
export default function ActuallProjects() {

  const [t, i18n] = useTranslation("global");
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language') || 'en');

  const handleChangeLanguage = async (lang) => {
    await i18n.changeLanguage(lang);
    localStorage.setItem('language', lang);
  };

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  return (
    <div>
      <section id="projects" className="alt-services section-bg">
        <div className="container" data-aos="fade-up">
          <h3 style={{ marginBottom: '20px', fontWeight: 'bold', }}>{t("Projects.name1")}</h3>
          <h5 style={{ marginBottom: '20px' }}>{t("Projects.sub1")}</h5>
          <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
            {/* Project Images (Left Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Image 1 */}
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/35.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
                {/* Image 2 */}
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/36.jpg" alt="Project 2" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
                {/* Image 3 */}
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/32.jpg" alt="Project 3" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
                {/* Image 4 */}
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/34.jpg" alt="Project 4" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/33.jpg" alt="Project 4" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
              </div>
            </div>

            {/* Project Description (Right Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Project Description */}
                <div className="col-md-12">
                  <br />
                  <h2 style={{ marginTop: '20px' }}>{t("Projects.sub2")}</h2>
                  <p style={{ marginTop: '10px' }}>
                    {t("Projects.sub3")}
                  </p>
                  <p>
                    {t("Projects.details")}
                  </p>
                  <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>
                    <li>{t("Projects.construction")}</li>
                    <li>{t("Projects.school")}</li>
                    <li>{t("Projects.toilets")}</li>
                    <li>{t("Projects.community_hall")}</li>
                    <li>{t("Projects.road_drainage")}</li>
                  </ul>
                  <p style={{ marginTop: '10px', fontSize: '18px', fontWeight: 'bold' }}>
                    {t("Projects.total_cost")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section id="projects1" className="projects">
        <div className="container" data-aos="fade-up">
          <h3>{t("Projects.name2")}</h3>
          <h5 style={{ marginBottom: '20px' }}>{t("Projects.project1")}</h5>
          <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
            {/* Project Images (Left Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Image 1 */}
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/38.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
                {/* Image 2 */}
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/39.jpg" alt="Project 2" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
              </div>
            </div>

            {/* Project Description (Right Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Project Description */}
                <div className="col-md-12">
                  <br />

                  <h2 style={{ margin: '1px' }}>{t("Projects.head1")}</h2>
                  <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>
                    <li>{t("Projects.area")}</li>
                    <li>{t("Projects.est1")}</li>
                    <li>{t("Projects.wal1")}</li>
                    <li>{t("Projects.win1")}</li>
                    <li>{t("Projects.roof1")}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section id="projects2" className="alt-services section-bg">
        <div className="container" data-aos="fade-up">
          <h5 style={{ marginBottom: '20px' }}>{t("Projects.project2")}</h5>
          <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
            {/* Project Images (Left Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Image 1 */}
                <div className="col-md-6">
                  <p style={{ fontSize: '24px', fontWeight: 'normal' }}>(A)</p>
                  <img src="../assets/img/projectimg/37.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
                {/* Image 2 */}
                <div className="col-md-6">
                  <p style={{ fontSize: '24px', fontWeight: 'normal' }}>(B)</p>
                  <img src="../assets/img/projectimg/40.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
              </div>
            </div>

            {/* First Project Description (Right Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Project Description */}
                <div className="col-md-12">
                  <br />
                  <h2>{t("Projects.head2")}</h2>
                  <br />
                  <h4 style={{ margin: '1px' }}> {t("Projects.head3")}</h4>
                  <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>
                    <li>{t("Projects.area1")}</li>
                    <li>{t("Projects.est2")}</li>
                    <li>{t("Projects.wal2")}</li>
                    <li>{t("Projects.win2")}</li>
                    <li>{t("Projects.roof2")}</li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Second Project Description (Right Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Project Description */}
                <div className="col-md-12">
                  <h4 style={{ margin: '1px' }}>{t("Projects.head4")}</h4>
                  <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>
                    <li>{t("Projects.area2")}</li>
                    <li>{t("Projects.est3")}</li>
                    <li>{t("Projects.wal3")}</li>
                    <li>{t("Projects.win3")}</li>
                    <li>{t("Projects.roof3")}</li>
                  </ul>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>


      <section id="projects3" className="alt-services section-bg">
        <div className="container" data-aos="fade-up">
          <h5 style={{ marginBottom: '20px' }}>{t("Projects.project3")}</h5>
          <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
            {/* Project Images (Left Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Image 1 */}
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/41.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
                {/* Image 2 */}
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/42.jpg" alt="Project 2" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
              </div>
            </div>

            {/* Project Description (Right Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Project Description */}
                <div className="col-md-12">
                  <br />
                  <h2 style={{ margin: '1px' }}>{t("Projects.head5")}</h2>
                  <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>
                    <li>{t("Projects.area3")}</li>
                    <li>{t("Projects.est4")}</li>
                    <li>{t("Projects.wal4")}</li>
                    <li>{t("Projects.win4")}</li>
                    <li>{t("Projects.roof4")}</li>
                    <li>{t("Projects.floor1")}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      <section id="projects4" className="projects">
        <div className="container" data-aos="fade-up">
          <h5 style={{ marginBottom: '20px' }}>{t("Projects.project4")}</h5>
          <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
            {/* Project Images (Left Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Image 1 */}
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/43.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
                {/* Image 2 */}
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/44.jpg" alt="Project 2" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
              </div>
            </div>

            {/* Project Description (Right Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Project Description */}
                <div className="col-md-12">
                  <br />
                  <h2 style={{ margin: '1px' }}>{t("Projects.head6")}</h2>
                  <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>
                    <li>{t("Projects.area4")}</li>
                    <li>{t("Projects.est5")}</li>
                    <li>{t("Projects.wal5")}</li>
                    <li>{t("Projects.win5")}</li>
                    <li>{t("Projects.roof5")}</li>
                    <li>{t("Projects.floor2")}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* d */}




      <section id="projects5" className="alt-services section-bg">
        <div className="container" data-aos="fade-up">
          <h5 style={{ marginBottom: '20px' }}>{t("Projects.project5")}</h5>
          <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
            {/* Project Images (Left Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Image 1 */}
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/45.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
                {/* Image 2 */}
              </div>
            </div>

            {/* Project Description (Right Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Project Description */}
                <div className="col-md-12">
                  <br />
                  <h2 style={{ margin: '1px' }}>{t("Projects.head7")}</h2>
                  <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>
                    <li>{t("Projects.area5")}</li>
                    <li>{t("Projects.est6")}</li>
                    <li>{t("Projects.wal6")}</li>
                    <li>{t("Projects.win6")}</li>
                    <li>{t("Projects.roof6")}</li>
                    <li>{t("Projects.floor3")}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* d */}

      <section id="projects6" className="projects">
        <div className="container" data-aos="fade-up">
          <h5 style={{ marginBottom: '20px' }}>{t("Projects.project6")}</h5>
          <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
            {/* Project Images (Right Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Image 1 */}
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/49.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/50.jpg" alt="Project 2" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/51.jpg" alt="Project 3" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
              </div>
            </div>

            {/* Project Description (Left Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Project Description */}
                <div className="col-md-12">
                  <br />
                  <h2 style={{ marginBottom: '1px' }}>{t("Projects.head8")}</h2>
                  <br />
                  <h4>{t("Projects.schem1")}</h4>
                  <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>
                    <li>{t("Projects.est7")}</li>
                    <li>{t("Projects.wal7")}</li>
                    <li>{t("Projects.win7")}</li>
                    <li>{t("Projects.roof7")}</li>
                  </ul>
                  <h2>{t("Projects.det1")}</h2>
                  <ul style={{ marginTop: '1px', paddingLeft: '20px' }}>
                    <li>{t("Projects.area6")}</li>
                    <li>{t("Projects.wal8")}</li>
                    <li>{t("Projects.win8")}</li>
                    <li>{t("Projects.roof8")}</li>
                    <li>{t("Projects.floor4")}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* d */}


      <section id="projects3" className="alt-services section-bg">
        <div className="container" data-aos="fade-up">
          <h5 style={{ marginBottom: '20px' }}>{t("Projects.project7")}</h5>
          <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
            {/* Project Images (Left Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Image 1 */}
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/47.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
                {/* Image 2 */}
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/48.jpg" alt="Project 2" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
              </div>
            </div>

            {/* Project Description (Right Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Project Description */}
                <div className="col-md-12">
                  <br />
                  <h2 style={{ margin: '1px' }}>{t("Projects.head9")}</h2>
                  <h5>{t("Projects.and")}</h5>
                  <h4>{t("Projects.schem2")}</h4>
                  <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>
                    <li>{t("Projects.est8")}</li>
                    <li>{t("Projects.wal9")}</li>
                    <li>{t("Projects.win9")}</li>
                    <li>{t("Projects.roof9")}</li>
                  </ul>
                  <h3>{t("Projects.det2")}</h3>
                  <ul style={{ marginTop: '1px', paddingLeft: '20px' }}>
                    <li>{t("Projects.area7")}</li>
                    <li>{t("Projects.wal10")}</li>
                    <li>{t("Projects.win10")}</li>
                    <li>{t("Projects.roof10")}</li>
                    <li>{t("Projects.floor5")}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* d */}


      <section id="projects5" className="projects">
        <div className="container" data-aos="fade-up">
          <h5 style={{ marginBottom: '20px' }}>{t("Projects.project8")}</h5>
          <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
            {/* Project Images (Left Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Image 1 */}
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/46.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
                {/* Image 2 */}
              </div>
            </div>

            {/* Project Description (Right Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Project Description */}
                <div className="col-md-12">
                  <br />
                  <h2 style={{ margin: '1px' }}>{t("Projects.head10")}</h2>
                  <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>
                    <li>{t("Projects.area8")}</li>
                    <li>{t("Projects.est9")}</li>
                    <li>{t("Projects.wal11")}</li>
                    <li>{t("Projects.win11")}</li>
                    <li>{t("Projects.roof11")}</li>
                    <li>{t("Projects.floor6")}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* d */}

      <section>
        <div class="container" data-aos="fade-up">
          <h5 style={{ marginBottom: '20px' }}>{t("Projects.project9")}</h5>
          <ul style={{ textAlign: 'center', listStylePosition: 'inside' }}>
            <li style={{ textAlign: 'left' }}>{t("Projects.l1")}</li>
            <li style={{ textAlign: 'left' }}>{t("Projects.l2")}</li>
            <li style={{ textAlign: 'left' }}>{t("Projects.l3")}</li>
            <li style={{ textAlign: 'left' }}>{t("Projects.l4")}</li>
            <li style={{ textAlign: 'left' }}>{t("Projects.l5")}</li>
            <li style={{ textAlign: 'left' }}>{t("Projects.l6")}</li>
            <li style={{ textAlign: 'left' }}>{t("Projects.l7")}</li>
          </ul>
        </div>
      </section>
      {/* d */}


      <section>
        <div class="container" data-aos="fade-up">
          <h5 style={{ marginBottom: '20px' }}>{t("Projects.project10")}</h5>
          <ul style={{ textAlign: 'center', listStylePosition: 'inside' }}>
            <li style={{ textAlign: 'left' }}>{t("Projects.l8")}</li>
            <li style={{ textAlign: 'left' }}>{t("Projects.l9")}</li>
            <li style={{ textAlign: 'left' }}>{t("Projects.l10")}</li>
            <li style={{ textAlign: 'left' }}>{t("Projects.l11")}</li>
          </ul>
        </div>
      </section>

      {/* d */}

      <section id="projects6" className="alt-services section-bg">
        <div className="container" data-aos="fade-up">
          <h5 style={{ marginBottom: '20px' }}>{t("Projects.project11")}</h5>
          <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
            {/* Project Images (Right Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Image 1 */}
                <div className="col-md-6">
                  <p style={{ fontSize: '18px', fontWeight: 'normal' }}>(A)</p>
                  <img src="../assets/img/projectimg/52.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                  <ul>
                    <li>{t("Projects.l12")}</li>
                    <li>{t("Projects.l13")}</li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <p style={{ fontSize: '18px', fontWeight: 'normal' }}>(B)</p>
                  <img src="../assets/img/projectimg/53.jpg" alt="Project 2" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                  <ul>
                    <li>{t("Projects.l14")}</li>
                    <li>{t("Projects.l15")}</li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <p style={{ fontSize: '18px', fontWeight: 'normal' }}>(C)</p>
                  <img src="../assets/img/projectimg/54.jpg" alt="Project 3" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                  <ul>
                    <li>{t("Projects.l16")}</li>
                    <li>{t("Projects.l17")}</li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <p style={{ fontSize: '18px', fontWeight: 'normal' }}>(D)</p>
                  <img src="../assets/img/projectimg/55.jpg" alt="Project 3" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                  <ul>
                    <li>{t("Projects.l118")}</li>
                    <li>{t("Projects.l18")}</li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Project Description (Left Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Project Description */}
                <div className="col-md-12">
                  <br />
                  <h2 style={{ marginBottom: '1px' }}>{t("Projects.head11")}</h2>
                  <br />
                  <h4>{t("Projects.head12")}</h4>
                  <br />
                  <ul style={{ marginTop: '1px', paddingLeft: '20px' }}>
                    <li>{t("Projects.l20")}</li>
                    <li>{t("Projects.l21")}</li>
                    <li>{t("Projects.l22")}</li>
                    <li>{t("Projects.l23")}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* d */}


      <section id="projects5" className="projects">
        <div className="container" data-aos="fade-up">
          <h5 style={{ marginBottom: '20px' }}>{t("Projects.project12")}</h5>
          <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
            {/* Project Images (Left Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Image 1 */}
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/56.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
                {/* Image 2 */}
              </div>
            </div>

            {/* Project Description (Right Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Project Description */}
                <div className="col-md-12">
                  <br />
                  <h2 style={{ margin: '1px' }}>{t("Projects.head13")}</h2>
                  <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>
                    <li>{t("Projects.area9")}</li>
                    <li>{t("Projects.est10")}</li>
                    <li>{t("Projects.wal12")}</li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* d */}


      <section id="projects5" className="alt-services section-bg">
        <div className="container" data-aos="fade-up">
          <h5 style={{ marginBottom: '20px' }}>{t("Projects.project13")}
          </h5>
          <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
            {/* Project Images (Left Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Image 1 */}
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/58.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
                {/* Image 2 */}
              </div>
            </div>

            {/* Project Description (Right Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Project Description */}
                <div className="col-md-12">
                  <br />
                  <h2 style={{ margin: '1px' }}>{t("Projects.head14")}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /d */}


      <section id="projects5" className="projects">
        <div className="container" data-aos="fade-up">
          <h5 style={{ marginBottom: '20px' }}>{t("Projects.project14")}
          </h5>
          <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
            {/* Project Images (Left Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Image 1 */}
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/59.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
                {/* Image 2 */}
              </div>
            </div>

            {/* Project Description (Right Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Project Description */}
                <div className="col-md-12">
                  <br />
                  <h2 style={{ margin: '1px' }}>{t("Projects.head15")}</h2>
                  <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>
                    <li>{t("Projects.area10")}</li>
                    <li>{t("Projects.est11")}</li>
                    <li>{t("Projects.wal13")}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* d */}

      <section id="projects5" className="alt-services section-bg">
        <div className="container" data-aos="fade-up">
          <h5 style={{ marginBottom: '20px' }}>{t("Projects.project15")}
          </h5>
          <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
            {/* Project Images (Left Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Image 1 */}
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/60.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
                {/* Image 2 */}
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/61.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
              </div>
            </div>

            {/* Project Description (Right Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Project Description */}
                <div className="col-md-12">
                  <br />
                  <h2 style={{ margin: '1px' }}>{t("Projects.head16")}</h2>
                  <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>
                    <li>{t("Projects.l24")}</li>
                    <li>{t("Projects.l25")}</li>
                    <li>{t("Projects.l26")}</li>
                    <li>{t("Projects.l27")}</li>
                    <li>{t("Projects.l28")}</li>
                    <li>{t("Projects.l29")}</li>
                    <li>{t("Projects.l30")}h</li>
                    <li>{t("Projects.l31")}</li>
                    <li>{t("Projects.l32")}</li>
                    <li style={{ fontSize: '18px', fontWeight: 'bold' }}>
                      {t("Projects.l33")}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* d */}


      <section id="projects6" className="projects">
        <div className="container" data-aos="fade-up">
          <h5 style={{ marginBottom: '20px' }}> {t("Projects.project16")}</h5>
          <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
            {/* Project Images (Right Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Image 1 */}
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/62.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />

                </div>
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/63.jpg" alt="Project 2" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />

                </div>
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/64.jpg" alt="Project 3" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />

                </div>
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/65.jpg" alt="Project 3" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* d */}

      <section id="projects6" className="alt-services section-bg">
        <div className="container" data-aos="fade-up">
          <h5 style={{ marginBottom: '20px' }}> {t("Projects.project17")}</h5>
          <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
            {/* Project Images (Right Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Image 1 */}
                <div className="col-md-6">
                  <p style={{ fontSize: '18px', fontWeight: 'normal' }}>(A)</p>
                  <img src="../assets/img/projectimg/66.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                  <ul>
                    <li> {t("Projects.l34")}</li>
                    <li> {t("Projects.l35")}</li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <p style={{ fontSize: '18px', fontWeight: 'normal' }}>(B)</p>
                  <img src="../assets/img/projectimg/67.jpg" alt="Project 2" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                  <ul>
                    <li> {t("Projects.l36")}</li>
                    <li> {t("Projects.l37")}</li>
                    <li> {t("Projects.l38")}</li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <p style={{ fontSize: '18px', fontWeight: 'normal', marginBottom: '1px' }}>(C)</p>
                  <img src="../assets/img/projectimg/68.jpg" alt="Project 3" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                  <ul>
                    <li> {t("Projects.l39")}</li>
                    <li> {t("Projects.l40")}</li>
                  </ul>
                </div>

              </div>
            </div>

            {/* Project Description (Left Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Project Description */}
                <div className="col-md-12">
                  <br />
                  <h2 style={{ marginBottom: '1px' }}> {t("Projects.head17")}</h2>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* d */}

      {/* 8 */}
      <section id="projects6" className="projects">
        <div className="container" data-aos="fade-up">
          <h5 style={{ marginBottom: '20px' }}>{t("Projects.head18")}</h5>

          <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
            {/* Project Images (Right Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Image 1 */}
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/70.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />

                </div>
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/71.jpg" alt="Project 2" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />

                </div>
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/72.jpg" alt="Project 3" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />

                </div>
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/73.jpg" alt="Project 3" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />

                </div>
              </div>
            </div>
            {/* Project Description (Left Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Project Description */}
                <div className="col-md-12">
                  <br />
                  <h3 style={{ marginBottom: '20px' }}>{t("Projects.head18")}</h3>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* d */}


      <section id="projects6" className="alt-services section-bg">
        <div className="container" data-aos="fade-up">
          <h5 style={{ marginBottom: '20px' }}>{t("Projects.project19")}</h5>
          <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
            {/* Project Images (Right Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Image 1 */}
                <div className="col-md-6">
                  <p style={{ fontSize: '18px', fontWeight: 'normal' }}>{t("Projects.p1")}</p>
                  <img src="../assets/img/projectimg/74.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />

                </div>
                <div className="col-md-6">
                  <p style={{ fontSize: '18px', fontWeight: 'normal' }}>{t("Projects.p2")}</p>
                  <img src="../assets/img/projectimg/75.jpg" alt="Project 2" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>

              </div>
            </div>

            {/* Project Description (Left Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Project Description */}
                <div className="col-md-12">
                  <br />
                  <h2 style={{ marginBottom: '1px' }}>{t("Projects.head19")}</h2>
                  <br />
                  <p>
                    {t("Projects.p3")}
                  </p>
                  <p>
                    {t("Projects.p4")}
                  </p>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* d */}


      <section id="projects6" className="projects">
        <div className="container" data-aos="fade-up">
          <h5 style={{ marginBottom: '20px' }}>{t("Projects.project20")}</h5>
          <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
            {/* Project Images (Right Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Image 1 */}
                <div className="col-md-6">
                  <p style={{ fontSize: '18px', fontWeight: 'normal' }}>(A)</p>
                  <img src="../assets/img/projectimg/76.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                  <h5>{t("Projects.head20")}</h5>
                </div>
                <div className="col-md-6">
                  <p style={{ fontSize: '18px', fontWeight: 'normal' }}>(B)</p>
                  <img src="../assets/img/projectimg/77.jpg" alt="Project 2" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                  <h5>{t("Projects.head21")} </h5>
                </div>

              </div>
            </div>

            {/* Project Description (Left Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Project Description */}
                <div className="col-md-12">
                  <br />
                  <h2 style={{ marginBottom: '1px' }}>{t("Projects.head22")} </h2>
                  <br />
                  <h4>{t("Projects.head23")}</h4>

                  <p style={{ fontSize: '18px', fontWeight: 'bold' }}>(A)</p>
                  <ul>
                    <li>{t("Projects.l41")}</li>
                    <li>{t("Projects.l42")}</li>
                    <li>{t("Projects.l43")}</li>
                    <li>{t("Projects.l44")}</li>
                    <li>{t("Projects.l45")}</li>
                  </ul>
                  <p style={{ fontSize: '18px', fontWeight: 'bold' }}>(B)</p>
                  <ul>
                    <li>{t("Projects.l46")}</li>
                    <li>{t("Projects.l47")}</li>
                    <li>{t("Projects.l48")}</li>
                    <li>{t("Projects.l49")}</li>
                    <li>{t("Projects.l50")}</li>
                  </ul>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* d */}

      <section id="projects5" className="alt-services section-bg">
        <div className="container" data-aos="fade-up">
          <h5 style={{ marginBottom: '20px' }}>{t("Projects.project21")}
          </h5>
          <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
            {/* Project Images (Left Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Image 1 */}
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/78.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
                {/* Image 2 */}
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/79.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/80.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
              </div>
            </div>

            {/* Project Description (Right Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Project Description */}
                <div className="col-md-12">
                  <br />
                  <h2 style={{ margin: '1px' }}>{t("Projects.head24")}</h2>
                  <br />
                  <h4>{t("Projects.head25")}</h4>
                  <h5>{t("Projects.head26")}</h5>
                  <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>
                    <li>{t("Projects.l51")}</li>
                    <li>{t("Projects.l52")}</li>
                    <li>{t("Projects.l53")}</li>
                    <li>{t("Projects.l54")}</li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* d */}

      <section id="projects5" className="projects">
        <div className="container" data-aos="fade-up">
          <h5 style={{ marginBottom: '20px' }}>{t("Projects.project22")}
          </h5>
          <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
            {/* Project Images (Left Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Image 1 */}
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/83.jpg" alt="Project 6" className="img-fluid project-image" style={{ width: '100%', height: '50px' }} />
                  <img src="../assets/img/projectimg/81.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '150px' }} />
                </div>
                {/* Image 2 */}
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/82.jpg" alt="Project 2" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/84.jpg" alt="Project 3" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/85.jpg" alt="Project 4" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/86.jpg" alt="Project 5" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/87.jpg" alt="Project 6" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
              </div>
            </div>

            {/* Project Description (Right Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Project Description */}
                <div className="col-md-12">
                  <br />
                  <h2 style={{ margin: '1px' }}>{t("Projects.head27")}</h2>
                  <br />
                  <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>
                    <li>{t("Projects.l55")}</li>
                    <li>{t("Projects.l56")}</li>
                    <li>{t("Projects.l57")}</li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* d */}

      <section id="projects5" className="alt-services section-bg">
        <div className="container" data-aos="fade-up">
          <h5 style={{ marginBottom: '20px' }}>{t("Projects.project23")}
          </h5>
          <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
            {/* Project Images (Left Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Image 1 */}
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/92.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '50px' }} />
                  <img src="../assets/img/projectimg/88.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '150px' }} />
                </div>
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/90.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
                {/* Image 2 */}
              </div>
            </div>

            {/* Project Description (Right Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Project Description */}
                <div className="col-md-12">
                  <br />
                  <h2 style={{ margin: '1px' }}>{t("Projects.head28")}</h2>
                  <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>
                    <li>{t("Projects.l58")}</li>
                    <li>{t("Projects.l59")}</li>
                    <li>{t("Projects.l60")}</li>
                    <li>{t("Projects.l61")}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* d */}


      <section id="projects6" className="projects">
        <div className="container" data-aos="fade-up">
          <h5 style={{ marginBottom: '20px' }}>{t("Projects.project24")}</h5>
          <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
            {/* Project Images (Right Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Image 1 */}
                <div className="col-md-6">

                  <img src="../assets/img/projectimg/93.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />

                </div>
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/94.jpg" alt="Project 2" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>

                <div className="col-md-6">

                  <img src="../assets/img/projectimg/95.jpg" alt="Project 2" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>

                <div className="col-md-6">
                  <img src="../assets/img/projectimg/96.jpg" alt="Project 2" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>

              </div>
            </div>

            {/* Project Description (Left Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Project Description */}
                <div className="col-md-12">
                  <br />
                  <h2 style={{ marginBottom: '1px' }}>{t("Projects.head29")}</h2>
                  <br />
                  <ul>
                    <li>{t("Projects.l62")}</li>
                    <br />
                    <li>{t("Projects.l63")}</li>
                  </ul>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* d */}
      <section id="projects6" className="alt-services section-bg">
        <div className="container" data-aos="fade-up">
          <h5 style={{ marginBottom: '20px' }}>{t("Projects.project25")}</h5>
          <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
            {/* Project Images (Right Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Image 1 */}

                <div className="col-md-6">
                  <img src="../assets/img/projectimg/100.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '40px' }} />
                  <img src="../assets/img/projectimg/98.jpg" alt="Project 2" className="img-fluid project-image" style={{ width: '100%', height: '150px' }} />
                </div>

                <div className="col-md-6">

                  <img src="../assets/img/projectimg/97.jpg" alt="Project 2" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>

                <div className="col-md-6">
                  <img src="../assets/img/projectimg/99.jpg" alt="Project 2" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>

                <div className="col-md-6">
                  <img src="../assets/img/projectimg/101.jpg" alt="Project 2" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>

                <div className="col-md-6">
                  <img src="../assets/img/projectimg/102.jpg" alt="Project 2" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>

                <div className="col-md-6">
                  <img src="../assets/img/projectimg/103.jpg" alt="Project 2" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>

              </div>
            </div>

            {/* Project Description (Left Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Project Description */}
                <div className="col-md-12">
                  <br />
                  <h2 style={{ marginBottom: '1px' }}>{t("Projects.head30")}</h2>
                  <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>
                    <li>{t("Projects.hp1")}</li>
                    <li>{t("Projects.hp2")}</li>

                  </ul>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* d */}

      <section id="projects5" className="projects">
        <div className="container" data-aos="fade-up">
          <h5 style={{ marginBottom: '20px' }}>{t("Projects.project26")}
          </h5>
          <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
            {/* Project Images (Left Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Image 1 */}
                <div className="col-md-6">
                  <p style={{ fontSize: '18px', fontWeight: 'normal' }}>(A)</p>
                  <img src="../assets/img/projectimg/106.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                  <h6>{t("Projects.head31")}</h6>
                </div>
                <div className="col-md-6">
                  <p style={{ fontSize: '18px', fontWeight: 'normal' }}>(B)</p>
                  <img src="../assets/img/projectimg/105.jpg" alt="Project 2" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                  <h6>{t("Projects.head32")}</h6>
                </div>
                {/* Image 2 */}
              </div>
            </div>

            {/* Project Description (Right Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Project Description */}
                <div className="col-md-12">
                  <br />
                  <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>

                    <h5 style={{ marginRight: '4px' }}>{t("Projects.head33")}</h5>
                    <li>{t("Projects.l64")}</li>
                    <li>{t("Projects.l65")}</li>
                    <li>{t("Projects.l66")}</li>
                    <li>{t("Projects.l67")}</li>
                  </ul>
                  <h5 style={{ marginLeft: '16px' }}>{t("Projects.head34")}</h5>
                  <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>
                    <li>{t("Projects.l68")}</li>
                    <li>{t("Projects.l69")}</li>
                    <li>{t("Projects.l70")}</li>
                    <li>{t("Projects.l71")}</li>
                  </ul>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* d */}


      <section id="projects5" className="alt-services section-bg">
        <div className="container" data-aos="fade-up">
          <h5 style={{ marginBottom: '20px' }}>{t("Projects.project27")}
          </h5>
          <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
            {/* Project Images (Left Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Image 1 */}
                <div className="col-md-6">
                  <p style={{ fontSize: '18px', fontWeight: 'normal' }}>{t("Projects.head35")}</p>
                  <img src="../assets/img/projectimg/109.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
                <div className="col-md-6">
                  <p style={{ fontSize: '18px', fontWeight: 'normal' }}>{t("Projects.head36")}</p>
                  <img src="../assets/img/projectimg/110.jpg" alt="Project 2" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
                {/* Image 2 */}
              </div>
            </div>

            {/* Project Description (Right Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Project Description */}
                <div className="col-md-12">
                  <br />
                  <h2 style={{ margin: '1px' }}>{t("Projects.head38")}</h2>
                  <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>

                    <h5 style={{ marginRight: '4px' }}>{t("Projects.head37")}</h5>
                    <li>{t("Projects.l75")}</li>
                    <li>{t("Projects.l76")}</li>
                    <li>{t("Projects.l77")}</li>
                    <li>{t("Projects.l78")}</li>


                  </ul>
                  <h5 style={{ marginLeft: '16px' }}>{t("Projects.head39")}</h5>
                  <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>
                    <li>{t("Projects.l72")}</li>
                    <li>{t("Projects.l73")}</li>
                    <li>{t("Projects.l74")}</li>
                  </ul>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* d */}

      <div id="projects6" className="projects">
        <div className="container" data-aos="fade-up">
          <h5 style={{ marginBottom: '20px' }}>{t("Projects.project28")}</h5>
          <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
            {/* Project Images (Right Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Image 1 */}

                <div className="col-md-6">

                  <img src="../assets/img/projectimg/111.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>

                <div className="col-md-6">

                  <img src="../assets/img/projectimg/113.jpg" alt="Project 2" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>

                <div className="col-md-6">
                  <img src="../assets/img/projectimg/114.jpg" alt="Project 3" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>

                <div className="col-md-6">
                  <img src="../assets/img/projectimg/115.jpg" alt="Project 4" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>

                <div className="col-md-6">
                  <img src="../assets/img/projectimg/116.jpg" alt="Project 4" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>

                <div className="col-md-6">
                  <img src="../assets/img/projectimg/117.jpg" alt="Project 6" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>

                <div className="col-md-6">
                  <img src="../assets/img/projectimg/118.jpg" alt="Project 7" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>

              </div>
            </div>

            {/* Project Description (Left Side) */}
            <div className="col-md-6" style={{ height: 'auto' }}>
              <div className="row gy-4">
                {/* Project Description */}
                <div className="col-md-12">
                  <br />
                  <h2 style={{ marginBottom: '1px' }}>{t("Projects.head40")}</h2>
                  <br />
                  <ul>

                    <li>{t("Projects.l79")}</li>
                    <li>{t("Projects.pp1")}</li>
                    <li>{t("Projects.pp2")}</li>
                    <li>{t("Projects.pp3")}</li>
                    {/* <li>{t("Projects.l80")}</li> */}
                  </ul>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>



    </div>
  );
}
