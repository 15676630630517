import React from 'react'
import Header2 from '../components/Header2'
import Footer from '../components/Footer'
import ProductionSection from '../components/ProductionSection'

export default function Production() {
  return (
    <div>
      <Header2 name="Production"/>
      <ProductionSection/>
      <Footer/>
    </div>
  )
}
