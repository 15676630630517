import React from 'react';

export default function ContactS() {
  return (
    <section id="contact"  className="alt-services section-bg" >
      <div className="container">
        <div className="map-container">
          <iframe
            style={{border: "none"}}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3885.6941660592333!2d78.165727975079!3d13.118550887210505!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3badf1858c924913%3A0xc87186df8c264397!2sNirmithi%20Kendra!5e0!3m2!1sen!2sin!4v1714742354790!5m2!1sen!2sin"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </section>
  );
}

// <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3885.6941660592333!2d78.165727975079!3d13.118550887210505!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3badf1858c924913%3A0xc87186df8c264397!2sNirmithi%20Kendra!5e0!3m2!1sen!2sin!4v1714742354790!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>