import React,{ useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
export default function ProjectComponent() {
  const [t, i18n] = useTranslation("global");
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language') || 'en');

  const handleChangeLanguage = async (lang) => {
    await i18n.changeLanguage(lang);
    localStorage.setItem('language', lang);
  };

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);


  return (
    <div>
      <section id="projects" className="projects">
        <div className="container" data-aos="fade-up">
          <h3 style={{ marginBottom: '20px',fontWeight:'bold',  }}>{t("Projects.name1")}</h3>
          <h5 style={{ marginBottom: '20px' }}>{t("Projects.sub1")}</h5>
          <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
            {/* Project Images (Left Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Image 1 */}
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/35.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
                {/* Image 2 */}
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/36.jpg" alt="Project 2" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
                {/* Image 3 */}
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/32.jpg" alt="Project 3" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
                {/* Image 4 */}
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/34.jpg" alt="Project 4" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/33.jpg" alt="Project 4" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
              </div>
            </div>

            {/* Project Description (Right Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Project Description */}
                <div className="col-md-12">
                  <br />
                  <h2 style={{ marginTop: '20px' }}>{t("Projects.sub2")}</h2>
                  <p style={{ marginTop: '10px' }}>
                  {t("Projects.sub3")}
                  </p>
                  <p>
                  {t("Projects.details")}
                  </p>
                  <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>
                    <li>{t("Projects.construction")}</li>
                    <li>{t("Projects.school")}</li>
                    <li>{t("Projects.toilets")}</li>
                    <li>{t("Projects.community_hall")}</li>
                    <li>{t("Projects.road_drainage")}</li>
                  </ul>
                  <p style={{ marginTop: '10px', fontSize: '18px', fontWeight: 'bold' }}>
                  {t("Projects.total_cost")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        </section>
      
{/* <section id="projects"  className="alt-services section-bg"> */}
      <section id="projects1" className="alt-services section-bg">
        <div className="container" data-aos="fade-up">
        <h3>{t("Projects.name2")}</h3>
          <h5 style={{ marginBottom: '20px' }}>{t("Projects.project1")}</h5>
          <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
            {/* Project Images (Left Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Image 1 */}
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/38.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
                {/* Image 2 */}
                <div className="col-md-6">
                  <img src="../assets/img/projectimg/39.jpg" alt="Project 2" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
                </div>
              </div>
            </div>

            {/* Project Description (Right Side) */}
            <div className="col-md-6">
              <div className="row gy-4">
                {/* Project Description */}
                <div className="col-md-12">
                  <br />
                  
                  <h2 style={{ margin: '1px' }}>{t("Projects.head1")}</h2>
                  <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>
                    <li>{t("Projects.area")}</li>
                    <li>{t("Projects.est1")}</li>
                    <li>{t("Projects.wal1")}</li>
                    <li>{t("Projects.win1")}</li>
                    <li>{t("Projects.roof1")}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        </section>
     

        <section id="projects3" className="projects">
  <div className="container" data-aos="fade-up">
    <h5 style={{ marginBottom: '20px' }}>{t("Projects.project3")}</h5>
    <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
      {/* Project Images (Left Side) */}
      <div className="col-md-6">
        <div className="row gy-4">
          {/* Image 1 */}
          <div className="col-md-6">
            <img src="../assets/img/projectimg/41.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
          </div>
          {/* Image 2 */}
          <div className="col-md-6">
            <img src="../assets/img/projectimg/42.jpg" alt="Project 2" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
          </div>
        </div>
      </div>

      {/* Project Description (Right Side) */}
      <div className="col-md-6">
        <div className="row gy-4">
          {/* Project Description */}
          <div className="col-md-12">
            <br />
            <h2 style={{ margin: '1px' }}>{t("Projects.head5")}</h2>
            <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>
              <li>{t("Projects.area3")}</li>
              <li>{t("Projects.est4")}</li>
              <li>{t("Projects.wal4")}</li>
              <li>{t("Projects.win4")}</li>
              <li>{t("Projects.roof4")}</li>
              <li>{t("Projects.floor1")}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  </section>

      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <button style={{ 
          padding: '10px 20px', 
          backgroundColor: 'khaki', 
          color: 'black', 
          border: 'none', 
          borderRadius: '5px', 
          cursor: 'pointer', 
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
          transition: 'box-shadow 0.3s' 
        }} 
        onClick={() => window.location.href = '/projects'}>
          {t("about.morepro")}
        </button>
      </div>
    </div>
  );
}
