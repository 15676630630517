import Header2 from '../components/Header2';
import Footer from '../components/Footer';
import TableSection from '../components/Tabledata';
import React, { useState, useEffect } from 'react';
import axios from 'axios';



export default function Rti() {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://kolarnk.up.railway.app/meetinglist/RTI');
        setTableData(response.data.meetingRTIs);
        setLoading(false);
        console.log('Fetched RTI data:', response.data.meetingRTIs);
      } catch (error) {
        console.error('Error fetching RTI data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <Header2 name="RTI" />

      {loading ? (
        <div className="text-center">
        <p>Loading...</p>
        <i className="fas fa-spinner fa-spin" style={{ fontSize: '2em', color: '#007bff' }}></i>
      </div>
      ) : (
        <TableSection data={tableData} />
      )}

      <Footer />
    </div>
  );
}
