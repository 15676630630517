import React,{ useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function OngoingProjects() {
  const [t, i18n] = useTranslation("global");
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language') || 'en');

  const handleChangeLanguage = async (lang) => {
    await i18n.changeLanguage(lang);
    localStorage.setItem('language', lang);
  };

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  return (
    <div>
      <section id="projects" className="alt-services section-bg">
  <div className="container" data-aos="fade-up">
    <h3 style={{ marginBottom: '20px', fontWeight: 'bold' }}>{t("Projects.ongoing")}</h3>
    {/* <h5 style={{ marginBottom: '20px' }}>{t("Projects.project29")}</h5> */}
    <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
      {/* Project Images (Left Side) */}
      <div className="col-md-6">
        <div className="row gy-4">
          {/* Image 1 */}
          <div className="col-md-6">
            <img src="../assets/img/projectimg/187.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
            <p>{t("Projects.p01")}</p>
          </div>
          {/* Image 2 */}
          <div className="col-md-6">
            <img src="../assets/img/projectimg/188.jpg" alt="Project 2" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
            <p> {t("Projects.p02")}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="projects2" className="projects">
  <div className="container" data-aos="fade-up">
    {/* <h5 style={{ marginBottom: '20px' }}>{t("Projects.project30")}</h5> */}
    <h4>{t("Projects.h4")}</h4>
    <h4>{t("Projects.h41")}</h4>
    <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
      {/* Project Images (Left Side) */}
      <div className="col-md-6">
        <div className="row gy-4">
          {/* Image 1 */}
          <div className="col-md-6">
            <img src="../assets/img/projectimg/189.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
          </div>
          {/* Image 2 */}
          <div className="col-md-6">
            <img src="../assets/img/projectimg/190.jpg" alt="Project 2" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="projects3" className="alt-services section-bg">
  <div className="container" data-aos="fade-up">
    {/* <h5 style={{ marginBottom: '20px' }}>{t("Projects.project31")}</h5> */}
    <h4>{t("Projects.h42")}</h4>
    <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
      {/* Project Images (Left Side) */}
      <div className="col-md-6">
        <div className="row gy-4">
          {/* Image 1 */}
          <div className="col-md-6">
            <img src="../assets/img/projectimg/191.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
          </div>
          {/* Image 2 */}
          <div className="col-md-6">
            <img src="../assets/img/projectimg/192.jpg" alt="Project 2" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>



<section id="projects4" className="projects">
  <div className="container" data-aos="fade-up">
    {/* <h5 style={{ marginBottom: '20px' }}>{t("Projects.project32")}</h5> */}
    <h4>{t("Projects.h43")}</h4>
    <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
      {/* Image 1 */}
      <div className="col-md-3">
        <img src="../assets/img/projectimg/193.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
        
      </div>
      {/* Image 2 */}
      <div className="col-md-3">
        <img src="../assets/img/projectimg/194.jpg" alt="Project 2" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
       
      </div>
      {/* Image 3 */}
      <div className="col-md-3">
        <img src="../assets/img/projectimg/195.jpg" alt="Project 3" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
      </div>
      {/* Image 4 */}
      <div className="col-md-3">
        <img src="../assets/img/projectimg/196.jpg" alt="Project 4" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
      </div>
    </div>
  </div>
</section>


<section id="projects5" className="alt-services section-bg">
  <div className="container" data-aos="fade-up">
    {/* <h5 style={{ marginBottom: '20px' }}>{t("Projects.project33")}</h5> */}
    <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
      {/* Project Images (Left Side) */}
      <div className="col-md-6">
        <div className="row gy-4">
          {/* Image 1 */}
          <div className="col-md-6">
            <img src="../assets/img/projectimg/197.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
            <p> {t("Projects.h5")}</p>
          </div>
          {/* Image 2 */}
          <div className="col-md-6">
            <img src="../assets/img/projectimg/198.jpg" alt="Project 2" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
            <p>{t("Projects.h6")}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="projects6" className="projects">
  <div className="container" data-aos="fade-up">
    {/* <h5 style={{ marginBottom: '20px' }}>{t("Projects.project34")}</h5> */}
    
    <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
      {/* Project Images (Left Side) */}
      <div className="col-md-6">
        <div className="row gy-4">
          {/* Image 1 */}
          <div className="col-md-6">
            <img src="../assets/img/projectimg/199.jpg" alt="Project 1" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
            <p> {t("Projects.p03")}</p>
          </div>
          {/* Image 2 */}
          <div className="col-md-6">
            <img src="../assets/img/projectimg/200.jpg" alt="Project 2" className="img-fluid project-image" style={{ width: '100%', height: '200px' }} />
            <p>{t("Projects.p04")} </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

      </div>
    
  );
}
