import React from 'react'
import Header2 from '../components/Header2'
import Footer from '../components/Footer'
import NewsSection from '../components/NewsSection'



export default function News() {
  return (
    <div>
      <Header2 name="News And Tenders"/>
      <NewsSection/>
      <Footer/>
    </div>
  )
}
