import React from 'react';


const MeetingImage = () => {


    return (
        <>          <section id="meeting-images" className="py-5">
            <div className="container">
                <h2 className="text-left mb-4">Meeting Images</h2>
                <div className="row g-4">
                    <div className="col-12 col-md-6">
                        <div className="card h-100" style={{ maxWidth: '85%', margin: 'auto' }}>
                            <img src="../assets/img/projectimg/14.png" className="card-img-top rounded" alt="Image 1 Description" style={{ height: 'auto', maxHeight: '300px' }} />
                            <div className="card-body">
                                <p className="card-text"> 14th Government body Meeting</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="card h-100" style={{ maxWidth: '85%', margin: 'auto' }}>
                            <img src="../assets/img/projectimg/15.png" className="card-img-top rounded" alt="Image 2 Description" style={{ height: 'auto', maxHeight: '300px' }} />
                            <div className="card-body">
                                <p className="card-text">15th Government body Meeting</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

            <section id="meeting-images" className="py-5">
                <div className="container">

                    <div className="row g-4">
                        <div className="col-12 col-md-6">
                            <div className="card h-100" style={{ maxWidth: '85%', margin: 'auto' }}>
                                <img src="../assets/img/projectimg/16.png" className="card-img-top rounded" alt="Image 1 Description" style={{ height: 'auto', maxHeight: '300px' }} />
                                <div className="card-body">
                                    <p className="card-text">17th Government body Meeting</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="card h-100" style={{ maxWidth: '85%', margin: 'auto' }}>
                                <img src="../assets/img/projectimg/17.png" className="card-img-top rounded" alt="Image 2 Description" style={{ height: 'auto', maxHeight: '300px' }} />
                                <div className="card-body">
                                    <p className="card-text">17th Government body Meeting</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="meeting-images" className="py-5">
                <div className="container">

                    <div className="row g-4">
                        <div className="col-12 col-md-6">
                            <div className="card h-100" style={{ maxWidth: '85%', margin: 'auto' }}>
                                <img src="../assets/img/projectimg/18.png" className="card-img-top rounded" alt="Image 1 Description" style={{ height: 'auto', maxHeight: '300px' }} />
                                <div className="card-body">
                                    <p className="card-text">18th Government body Meeting</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="card h-100" style={{ maxWidth: '85%', margin: 'auto' }}>
                                <img src="../assets/img/projectimg/19.png" className="card-img-top rounded" alt="Image 2 Description" style={{ height: 'auto', maxHeight: '300px' }} />
                                <div className="card-body">
                                    <p className="card-text">19th Government body Meeting</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="meeting-images" className="py-5">
                <div className="container">

                    <div className="row g-4">
                        <div className="col-12 col-md-6">
                            <div className="card h-100" style={{ maxWidth: '85%', margin: 'auto' }}>
                                <img src="../assets/img/projectimg/20.png" className="card-img-top rounded" alt="Image 1 Description" style={{ height: 'auto', maxHeight: '300px' }} />
                                <div className="card-body">
                                    <p className="card-text">20th Government body Meeting</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="card h-100" style={{ maxWidth: '85%', margin: 'auto' }}>
                                <img src="../assets/img/projectimg/21.png" className="card-img-top rounded" alt="Image 2 Description" style={{ height: 'auto', maxHeight: '300px' }} />
                                <div className="card-body">
                                    <p className="card-text">21th Government body Meeting</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="meeting-images" className="py-5">
                <div className="container">

                    <div className="row g-4">
                        <div className="col-12 col-md-6">
                            <div className="card h-100" style={{ maxWidth: '85%', margin: 'auto' }}>
                                <img src="../assets/img/projectimg/22.png" className="card-img-top rounded" alt="Image 1 Description" style={{ height: 'auto', maxHeight: '300px' }} />
                                <div className="card-body">
                                    <p className="card-text">22th Government body Meeting</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="card h-100" style={{ maxWidth: '85%', margin: 'auto' }}>
                                <img src="../assets/img/projectimg/23.png" className="card-img-top rounded" alt="Image 2 Description" style={{ height: 'auto', maxHeight: '300px' }} />
                                <div className="card-body">
                                    <p className="card-text">23th Government body Meeting</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="meeting-images" className="py-5">
                <div className="container">

                    <div className="row g-4">
                        <div className="col-12 col-md-6">
                            <div className="card h-100" style={{ maxWidth: '85%', margin: 'auto' }}>
                                <img src="../assets/img/projectimg/24.png" className="card-img-top rounded" alt="Image 1 Description" style={{ height: 'auto', maxHeight: '300px' }} />
                                <div className="card-body">
                                    <p className="card-text">24th Government body Meeting</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="card h-100" style={{ maxWidth: '85%', margin: 'auto' }}>
                                <img src="../assets/img/projectimg/25.png" className="card-img-top rounded" alt="Image 2 Description" style={{ height: 'auto', maxHeight: '300px' }} />
                                <div className="card-body">
                                    <p className="card-text">25th Government body Meeting</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section id="meeting-images" className="py-5">
                <div className="container">

                    <div className="row g-4">
                        <div className="col-12 col-md-6">
                            <div className="card h-100" style={{ maxWidth: '85%', margin: 'auto' }}>
                                <img src="../assets/img/projectimg/26.png" className="card-img-top rounded" alt="Image 1 Description" style={{ height: 'auto', maxHeight: '300px' }} />
                                <div className="card-body">
                                    <p className="card-text">26th Government body Meeting</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="card h-100" style={{ maxWidth: '85%', margin: 'auto' }}>
                                <img src="../assets/img/projectimg/27.png" className="card-img-top rounded" alt="Image 2 Description" style={{ height: 'auto', maxHeight: '300px' }} />
                                <div className="card-body">
                                    <p className="card-text">27th Government body Meeting</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>






        </>

    );
};

export default MeetingImage;
